@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli");



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

body {
  font-family: Muli, sans-serif;
}

.freeAmount {
  color: green;
}

.btnWrapperHome {
  display: flex;
  align-items: center;
}

.btnWrapperHome .loginBtn {
  margin-left: 16px;
  background: lightgray;
  color: black;
}

.btnWrapperHome .loginBtn:hover {
  background: rgb(154, 151, 151);
  color: black;
}