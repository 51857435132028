.searchBoxWrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchSection {
    /* background: url("../assets/Barbering.jpg"); */
    width: 100%;
    min-height: 60vh;
    padding: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    background: #000000
}


.searchSection .searchBox {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    padding: 30px;
    gap: 2rem;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 0px #000;
}

.searchSection .headerText {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    text-align: center;
    font-weight: bold;
    padding: 30px;

}

.searchSection .searchBox .types {
    display: flex;
}

.types .icon {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 0;
    padding-bottom: 5px;
    cursor: pointer;
}

.types .icon.active {
    border-bottom: 3px solid crimson;

}

.recommended-shops-wrapper {
    margin: 40px 0;
}


.recommended-shops-wrapper h2 {
    margin: 20px 0;
}

.recommended-shops-wrapper .recommended-shops {
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
}

.recommended-shops .card {
    width: calc(33.33% - 1em);
}

.card-inner-img {
    margin: 0;
    padding: 0;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.card-img-part {
    max-width: 100%;
    transform-origin: center;
    transform: scale(1.001);
    transition: transform 0.4s ease-in-out;
}

.card-main-box:hover .card-img-part {
    transform: scale(1.1);
}

@media only screen and (max-width: 1024px) {
    .searchSection {
        padding: 100px 0px;

    }
}

@media only screen and (max-width: 991px) {
    .recommended-shops .card {
        width: calc(50% - 1em);
    }

    .searchSection {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .recommended-shops .card {
        width: calc(100% - 1em);
    }

    .searchSection .headerText {
        font-size: 32px;
    }
}

@media only screen and (max-width: 576px) {
    .searchSection .headerText {
        font-size: 32px;
    }

    .searchSection {
        flex-direction: column-reverse;
        padding: 0;
        min-height: auto;
    }

    .searchBoxWrapper {
        width: 100%;
    }

    .searchSection .headerText {
        font-size: 36px;
        width: 100%;
    }


}

@media only screen and (max-width: 576px) {
    .searchSection .headerText {
        font-size: 26px;
        width: 100%;
    }
}