.staff-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;

}

.staff-section .sidebar {
    width: 20%;
}

.staff-section .sidebar .company-name {
    margin-bottom: 15px;
    text-align: center;
}

.sidebar .side-links .link-container {
    display: flex;
    padding: 16px;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
}

.sidebar .side-links .link-container.selected {
    background-color: rgba(29, 144, 245, .2)
}

.sidebar .side-links .link-container .icon {
    margin-right: 15px;
    color: rgb(160, 168, 174);
}

.sidebar .side-links .link-container div {
    color: black;
}

.sidebar .side-links .link-container.selected .icon {
    color: #1d90f5;

}

.staff-section .main {
    width: calc(55% - 30px);
    padding: 40px;
    box-shadow: 0px 0px 5px 0px #5a55557d;
    border-radius: 8px;

}

.staff-section .main .header {
    margin-bottom: 15px;
}

.main .staff-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    cursor: pointer;
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
}

.main .staff-wrapper:last-child {
    border: none;
}

.main .staff-wrapper .details {
    display: flex;
    align-items: center;
    gap: 1em;
}

.main .topbar {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-bottom: 30px;
}

.main .services-list .serviceListHeader {
    margin-bottom: 30px;
}

.main .servicesWrapper {
    min-height: 210px;
}

.main .confirmSelection {
    display: flex;
    justify-content: flex-end;
}

.main .detailsOfService {
    padding: 10px 16px;
    min-height: 64px;
    padding: 24px 16px;
    background-color: #f6f8fc;
    border-radius: 6px;
    margin-bottom: 24px;
}

.main .detailsOfService h4 {
    font-weight: 700;
    margin-bottom: 8px;
    color: rgb(26, 26, 26);
}

.main .detailsOfService p {
    font-size: 14px;
}


.staff-section .working-hours {
    width: calc(25% - 30px);
    position: sticky;
    top: 10px;
}

.staff-section .working-hours .about-content {
    padding: 0;
}

.staff-section .addTimeContainer {
    width: 100%;
}

.staff-section .slot-title-part {
    flex-direction: column;
    gap: 8px;
}

@media only screen and (max-width: 1200px) {
    .staff-section .booking-wrapper .dateAndSlots {
        flex-direction: column;
        gap: 30px;
    }

    .staff-section .booking-wrapper .wrapperDate {
        margin-right: 0;
    }

    .staff-section .booking-wrapper .dateAndSlots .datePicker {
        margin: 0 auto !important;
    }

    .staff-section .timing .timimgContainer {
        font-size: 12px;
    }
}

@media only screen and (max-width: 991px) {
    .staff-section .sidebar {
        width: 100%;
    }

    .staff-section .main {
        width: calc(65% - 30px);
    }

    .staff-section .working-hours {
        width: 35%;
    }
}

@media only screen and (max-width: 767px) {

    .staff-section .main,
    .staff-section .working-hours {
        width: 100%;
    }
    .staff-section .booking-wrapper .dateAndSlots .datePicker {
        width: 100%;
    }
}