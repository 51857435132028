.slider {
    height: 70vh;
}

.slider-image {
    height: 100%;
}

.slider-image img {
    height: 100%;
}