.booking-wrapper {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    gap: 60px;
    align-items: flex-start;
}

.booking-wrapper .booking-criteria {
    flex: 1;
    border-radius: 8px;
    /* border: 2px solid #000; */
    box-shadow: 0px 0px 10px 1px #cacaca;
    padding: 30px;
}

.booking-wrapper .dateAndSlots {
    display: flex;
    width: 100%;
    padding: 30px 0;
}

.wrapperDate h2 {
    margin-bottom: 16px;
}

.booking-form-container .formWrapper .form-control .phoneInput {
    width: 100%;
    height: 60px;
}

.booking-wrapper .dateAndSlots .datePicker {
    /* width: 100%; */
    box-shadow: 0px 0px 10px 1px #cacaca;
    padding: 10px;
}

.booking-wrapper .wrapperDate {
    margin-right: 30px;
}

.booking-wrapper .wrapper {
    width: fit-content;
}

.booking-wrapper .slotsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 16px;
}

.booking-wrapper .stepper {
    width: 400px;
    margin-bottom: 30px
}

.booking-details {
    max-width: 30%;
    flex: 1;
}

.slotsWrapper button {
    min-width: calc(25% - 1em);
}

.dateAndSlots .slotBtn:hover {
    color: white;
}

.slot-title-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slot-part-color-box {
    display: flex;
    gap: 15px;
}

.slot-part-color-box h5 {
    display: flex;
    align-items: center;
    gap: 5px;
}

.slot-part-color-box h5 span {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: inline-block;
    /* background: grey; */
    border: 1px solid #1976d2;
}

.booking-details {
    position: sticky;
    top: 10px;
}

.slot-part-color-box h5:last-child span {
    background: #1976d2;
}

.timezone-wrapper {
    margin-bottom: 16px;
}

button {
    line-height: normal;
}

@media only screen and (max-width: 1260px) {
    .slot-title-part {
        align-items: start;
        flex-direction: column;
        gap: 12px;
    }
}

@media only screen and (max-width: 992px) {

    .booking-criteria,
    .booking-details {
        max-width: 50%;
    }

    .booking-wrapper .stepper {
        width: 300px;
    }

    .booking-wrapper .dateAndSlots {
        flex-direction: column;
        gap: 32px;
    }

    .booking-wrapper .wrapperDate {
        margin-right: 0;
    }

    .slotsWrapper button {
        min-width: calc(33.33% - 0.8em);
    }

    .booking-wrapper .slotsWrapper {
        gap: 0.8em;
    }

    .slot-title-part {
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
    }

    .booking-wrapper {
        align-items: flex-start;
        position: relative;
    }

    .booking-details {
        position: sticky;
        top: 10px;
    }

    .booking-wrapper .dateAndSlots .datePicker {
        margin: 0 auto !important;
    }


}

@media only screen and (max-width: 767px) {
    .booking-wrapper .stepper {
        width: 270px;
    }

    .booking-wrapper {
        flex-direction: column;
    }

    .booking-criteria,
    .booking-details {
        width: 100%;
        max-width: 100%;
    }

    .booking-wrapper .slotsWrapper {
        justify-content: space-between;
    }
}

@media only screen and (max-width: 420px) {
    .slotsWrapper button {
        min-width: calc(50% - 0.8em);
    }
}