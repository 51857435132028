.main .accordianCustom {
    min-height: 325px;
}

.classes .durationWrapper {
    display: flex;
    align-items: center;
}

.classes .wrapper2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.classes .wrapper {
    width: 100%;
}

.classes .booking-wrapper .slotsWrapper {
    justify-content: center;
}