.about-content {
    display: flex;
    padding: 40px 40px;
    gap: 3em;
    justify-content: space-between;
}

.about-content .content-details {
    width: 60%;
}

.about-content .content-details h2 {
    padding: 0px 0px 24px;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    color: #0d1619;
}

.addTimeContainer .address {
    margin-bottom: 24px;
}

.about-content .content-details .discription {
    margin-bottom: 16px;
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #26292e;
    white-space: break-spaces;
}

/* .timimgContainer p:first-child {
    color: #1976d2;
    font-weight: 600;
} */

.about-content .content-details .discription p {
    height: 264px;
    overflow: hidden;
    transition: height 200ms ease 0s;
    position: relative;
}

.addTimeContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 24px;
}

.addTimeContainer.position {
    margin-top: -100px;
    position: relative;
    z-index: 9;
}

.addTimeContainer .address h2 {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
}

.addTimeContainer .address .locationDetails {
    text-align: center;
}

.addTimeContainer .address .locationIconText {
    display: flex;
    align-items: center;
    gap: 0.5em;
    padding: 0px 0px 8px;
    justify-content: center;
}

.addTimeContainer .address {
    margin-bottom: 24px;
    padding: 16px;
    text-align: center;
    box-shadow: 0px 0px 5px 0px #000;
    border-radius: 8px;
    background-color: #000;
    color: #fff;
}

.locationIcon {
    display: flex;
}

.timing {
    padding: 28px;
    text-align: center;
    box-shadow: 0px 0px 5px 0px #000;
    border-radius: 8px;
    background: #000;
}

.addTimeContainer .address .locationDetails div {
    flex: 0 0 150px;
}

.addTimeContainer .timing h2 {
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 1rem;
    color: #fff;
}

.timing .timimgContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    gap: 1.8rem;
    color: #fff;
}