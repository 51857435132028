.booking-success {
    margin: 40px auto;
    text-align: center;
    color: white;
}

.booking-success h1 {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.booking-success p {
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.booking-success i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.booking-success .card {
    background-color: #000;
    padding: 60px;
    border-radius: 16px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}

.booking-success .customCheckmark {
    border-radius: 200px;
    height: 200px;
    width: 200px;
    background: #F8FAF5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.booking-success .buttons {
    display: flex;
    justify-content: center;
    margin: 30px auto;
    gap: 16px;
}

.booking-success .buttons button {
    width: 200px;
}

.booking-success .buttons button a,
button {
    line-height: 1.8 !important;
}

.booking-success .buttons button:first-child a {
    color: #fff !important;
}

@media only screen and (max-width: 767px) {
    .booking-success .customCheckmark {
        width: 150px;
        height: 150px;
    }
    .booking-success h1 {
        font-size: 36px;
    }
    .booking-success p {
        font-size: 16px;
    }
    .booking-success .card {
        padding: 18px;
    }

}
@media only screen and (max-width: 520px) {
    .booking-success .buttons {
        flex-direction: column;
        align-items: center;
    }
}