.single-service-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    margin: 15px 0;
}

.service-details {
    display: flex;
}

.selection-box {
    width: 50px;
    height: 50px;
    border: 1px solid grey;
    border-radius: 25px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-part .MuiTabs-vertical {
    border: none;
    width: 30%;
}

.MuiTabs-indicator {
    display: none;
}

.tab-part .MuiTabs-vertical .Mui-selected {
    background-color: #1976d2;
    color: #fff;
    border-radius: 8px;
    clip-path: polygon(90% 0, 95% 50%, 90% 100%, 0% 100%, 0 50%, 0% 0%);
}

.tab-tab {
    border-radius: 8px;
    /* border: 2px solid #000; */
    box-shadow: 0px 0px 10px 1px #cacaca;
    width: 70%;
}

.tab-part .MuiTabs-vertical .Mui-selected {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    clip-path: polygon(90% 0, 95% 50%, 90% 100%, 0% 100%, 0 50%, 0% 0%);
}

.tab-title-name button {
    font-size: 18px;
    font-weight: 600;
}

.service-price {
    font-size: 22px;
    font-weight: 600;
}

.tab-part {
    padding-bottom: 60px;
}

@media only screen and (max-width: 991px) {
    .about-content {
        padding: 40px 16px;
    }

    .about-content .content-details,
    .addTimeContainer {
        width: 50%;
    }

    .floating-footer {
        width: 90%;
    }

    .service-container {
        padding-bottom: 80px;
    }

}

@media only screen and (max-width: 767px) {
    .about-content {
        flex-direction: column-reverse;
        padding: 40px 12px;
        gap: 0
    }

    .about-content .content-details,
    .addTimeContainer,
    .addTimeContainer {
        width: 100%;
    }

    .timing {
        padding: 16px;
    }

    .about-content .content-details h2,
    .about-content .content-details .discription {
        text-align: center;
    }

    .floating-footer {
        background: inherit;
        flex-direction: column;
    }

    .selectedServices {
        width: 100%;
        padding: 12px;
        text-align: center;
        font-size: 16px;
        border-radius: 4px;
        background: #000;
    }

    .floating-footer button {
        padding: 12px;
        width: 100%;
    }

    .service-container {
        padding-bottom: 120px;
    }
    .slider {
        height: 100%;
    }
}