.top-header-name h2.headerName {
    padding: 32px;
    font-size: 32px;
}

.customerBookingWrapper {
    padding: 16px;
}

.customerBookingWrapper .filterBookingTypes button {
    margin: 16px;
}

.customerBookingWrapper .allBookingsContainer {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
}

.customerBookingWrapper .singleBooking {
    background: lavender;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 170px;
    width: 30%;
    padding: 16px;
    border-radius: 16px;
    margin: 16px;
}

.customerBookingWrapper .singleBooking .detailsWrapper {
    display: flex;
    justify-content: space-between;
}

.customerBookingWrapper .singleBooking .container {
    width: 180px;
}