.floating-footer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background: #000;
    color: #fff;
    align-items: center;
    border-radius: 4px;
    bottom: 10px;
    z-index: 9;
}

.selectedServices {
    padding-left: 16px;
    font-size: 22px;
    font-weight: 600;
}

.floating-footer button {
    padding: 16px;
}
