.booking-detailsWrapper {
    background-color: rgba(2, 2, 2, 1);
    padding: 30px;
    color: white;
    border-radius: 16px;
}

.booking-detailsWrapper .image {
    width: 100px;
    height: 100px;
    margin: 15px auto;
    background: aliceblue;
}

.booking-detailsWrapper h4 {
    text-align: center;
    font-size: 18px;
    margin: 15px;
}

.booking-detailsWrapper p {
    text-align: center;
    font-size: 16px;
    margin: 15px;
}

.booking-detailsWrapper .para {
    margin: 15px auto;
    font-size: 16px;
    width: 200px;
    text-align: center;
}

.booking-detailsWrapper .services {
    display: flex;
    justify-content: space-between;
}

.services-list-details>p {
    text-align: left;
    margin: 0 15px 15px;
}

.booking-detailsWrapper .total {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
}
